
import {
    FolderAddOutlined,
    FileAddOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import temJSON from "./components/temJSON.vue"
import {
    createVNode
} from "vue"
//eslint-disable-next-line
var reg = new RegExp('[\\\\/:*?\"<>|]');
export default {
    components: {
        FolderAddOutlined,
        FileAddOutlined,
        DeleteOutlined,
        temJSON
    },
    data() {
        return {
            data: [{
                title: "lang",
                path: "/lang",
                key: "0",
                children: [{
                        key: "0-0",
                        title: "admin",
                        path: "/admin",
                        children: [{
                                key: "0-0-0",
                                title: "account",
                                path: "/admin/account",
                                children: [{
                                        key: "0-0-0-0",
                                        title: "charge.json",
                                        path: "/admin/account/charge.json"
                                    },
                                    {
                                        key: "0-0-0-1",
                                        title: "charge_group.json",
                                        path: "/admin/account/charge_group.json"
                                    },
                                    {
                                        key: "0-0-0-2",
                                        title: "deposit.json",
                                        path: "/admin/account/deposit.json"
                                    },
                                    {
                                        key: "0-0-0-3",
                                        title: "express_invoice.json",
                                        path: "/admin/account/express_invoice.json"
                                    },
                                    {
                                        key: "0-0-0-4",
                                        title: "withdraw.json",
                                        path: "/admin/account/withdraw.json"
                                    }
                                ]
                            },
                            {
                                key: "0-0-1",
                                title: "basic",
                                path: "/admin/basic",
                                children: [{
                                        key: "0-0-1-0",
                                        title: "address_book.json",
                                        path: "/admin/basic/address_book.json"
                                    },
                                    {
                                        key: "0-0-1-1",
                                        title: "admin.json",
                                        path: "/admin/basic/admin.json"
                                    },
                                    {
                                        key: "0-0-1-2",
                                        title: "country.json",
                                        path: "/admin/basic/country.json"
                                    },
                                    {
                                        key: "0-0-1-3",
                                        title: "shipper.json",
                                        path: "/admin/basic/shipper.json"
                                    },
                                    {
                                        key: "0-0-1-4",
                                        title: "user.json",
                                        path: "/admin/basic/user.json"
                                    }
                                ]
                            },
                            {
                                key: "0-0-2",
                                title: "config",
                                path: "/admin/config",
                                children: [{
                                        key: "0-0-2-0",
                                        title: "basic.json",
                                        path: "/admin/config/basic.json"
                                    },
                                    {
                                        key: "0-0-2-1",
                                        title: "content.json",
                                        path: "/admin/config/content.json"
                                    },
                                    {
                                        key: "0-0-2-2",
                                        title: "menu",
                                        path: "/admin/config/menu",
                                        children: [{
                                                key: "0-0-2-2-0",
                                                title: "admin.json",
                                                path: "/admin/config/menu/admin.json"
                                            },
                                            {
                                                key: "0-0-2-2-1",
                                                title: "user.json",
                                                path: "/admin/config/menu/user.json"
                                            }
                                        ]
                                    },
                                    {
                                        key: "0-0-2-3",
                                        title: "notice.json",
                                        path: "/admin/config/notice.json"
                                    }
                                ]
                            },
                            {
                                key: "0-0-3",
                                title: "order",
                                path: "/admin/order",
                                children: [{
                                        key: "0-0-3-0",
                                        title: "order.json",
                                        path: "/admin/order/order.json"
                                    },
                                    {
                                        key: "0-0-3-1",
                                        title: "rates.json",
                                        path: "/admin/order/rates.json"
                                    }
                                ]
                            },
                            {
                                key: "0-0-4",
                                title: "ship_method",
                                path: "/admin/ship_method",
                                children: [{
                                        key: "0-0-4-0",
                                        title: "api.json",
                                        path: "/admin/ship_method/api.json"
                                    },
                                    {
                                        key: "0-0-4-1",
                                        title: "method.json",
                                        path: "/admin/ship_method/method.json"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        key: "0-1",
                        title: "common.json",
                        path: "/common.json"
                    },
                    {
                        key: "0-2",
                        title: "dashboard",
                        path: "/dashboard",
                        children: [{
                            key: "0-2-0",
                            title: "admin.json",
                            path: "/dashboard/admin.json"
                        }]
                    },
                    {
                        key: "0-3",
                        title: "en.js",
                        path: "/en.js"
                    },
                    {
                        key: "0-4",
                        title: "zh.js",
                        path: "/zh.js"
                    },
                    {
                        key: "0-5",
                        title: "zh_CN.json",
                        path: "/zh_CN.json"
                    }
                ]
            }],
            /* 当前修改文件 */
            current_file: "/common.json",
            /* 控制选择 */
            selectedKeys: ["0-1"],
            /* 展开 */
            expandedKeys: ["0"],
            /* 加载状态 */
            loading: false,
            /* 处理展现形式 */
            isJSON: false,
            /* 查询 */
            where: {
                keywords: ""
            },
            /* 列表接收 */
            getJSONData: {},
            /* 模糊框文件 */
            modalFile: {
                /* 新增文件 */
                visibleFile: false,
                /* 文件名 */
                fileName: ""
            }
        };
    },
    watch: {
        isJSON(val) {
            if (val) {
                this.getJsonText();
            }
        }
    },
    computed: {
        dataSource() {
            const obj = this.getJSONData;
            const keys = Object.keys(obj);
            return keys.map((key, index) => {
                return {
                    index,
                    key,
                    value: obj[key]
                }
            }).filter(item => typeof item.value == 'string' && item.value.indexOf(this.where.keywords) > -1 || item.key.indexOf(this.where.keywords) > -1);
        },
        columns() {
            return [{
                    key: 'index',
                    width: 58,
                    customRender: ({
                        index
                    }) => index + 1
                },
                {
                    key: 'key',
                    dataIndex: 'key',
                    title: this.$t('标识符'),
                    width: 300
                },
                {
                    key: 'value',
                    dataIndex: 'value',
                    title: this.$t('译文'),
                    slots: {
                        customRender: "value"
                    }
                }
            ]
        }
    },
    methods: {
        // 获取JSON文件
        getJsonText() {
            this.loading = true;
            this.$http
                .get("/getLang" + this.current_file, {
                    baseURL: process.env.VUE_APP_API_LANG_URL
                })
                .then(res => {
                    this.getJSONData = res.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 提交
        submit() {
            if (this.isJSON) {
                this.getJSONData = this.$refs.temJSONRef.getData();
            } else {
                this.dataSource.forEach(item => {
                    this.getJSONData[item.key] = item.value;
                })
            }
            this.$http.post('/updateLang', {
                filePath: this.current_file,
                content: JSON.stringify(this.getJSONData)
            }, {
                baseURL: process.env.VUE_APP_API_LANG_URL
            }).then(res => {
                if (res.status == 200 && res.data.code == 0) {
                    this.$message.success('操作成功');
                }
            })
        },
        /* 获取文件目录 */
        getFileDir() {
            this.$http.get('/getFileDir', {
                baseURL: process.env.VUE_APP_API_LANG_URL
            }).then(res => {
                if (res.data.code == 0) {
                    this.data[0].children = res.data.data;
                }
            });
        },
        /* 点击树事件 */
        handleTreeClick(keys, {
            node
        }) {
            const row = node.dataRef;
            this.current_file = row.path;

            if (!row.children) {
                this.getJsonText();
            }

        },
        reload() {
            this.$refs.table.reload({
                page: 1
            })
        },
        /* 新建文件夹or文件 */
        handleAddFile(type) {
            if (this.current_file.indexOf('.') > -1) {
                return
            }
            this.modalFile.visibleFile = true;
            this.modalFile.type = type
        },
        /* save文件 */
        newFile() {
            if (!this.modalFile.fileName || reg.test(this.modalFile.fileName)) {
                return
            }
            this.$http.post('/foundFile', {
                type: this.modalFile.type,
                file_path: this.current_file,
                file_name: this.modalFile.fileName
            }, {
                baseURL: process.env.VUE_APP_API_LANG_URL
            }).then((res) => {
                if (res.data.code == 0) {
                    this.getFileDir();
                    this.modalFile.visibleFile = false;
                    this.modalFile.fileName = "";
                    this.$message.success('操作成功');
                }
            })
        },
        /* 删除文件/文件夹 */
        removeFile() {
            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('removeFiles', {
                        file_path: this.current_file
                    }, {
                        baseURL: process.env.VUE_APP_API_LANG_URL
                    }).then(res => {
                        hide();
                        if (res.data.code == 0) {
                            this.getFileDir();
                            this.$message.success('操作成功')
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        }
    },
    created() {
        this.getJsonText();
        this.getFileDir();
    }
};
