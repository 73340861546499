
import jsoneditor from "jsoneditor";
export default {
    props: {
        data: Object
    },
    data() {
        return {
            jsonEditor: null
        };
    },
    watch:{
        data(val){
            this.jsonEditor.set(val);
        }
    },
    methods: {
        // 初始化jsonEditor
        initJsonEditor() {
            var container = document.getElementById("jsoneditor");
            var options = {
                mode: "code",
                modes: ["code"], // allowed modes
                onError: function (err) {
                    console.log(err);
                }
            };
            var editor = new jsoneditor(container, options);
            this.jsonEditor = editor;
        },
        getData() {
            return this.jsonEditor.get()
        }
    },
    mounted() {
        this.initJsonEditor();
    }
};
